import moment from "moment";

$(document).ready(function () {
    var photoNum = 1;
    var i = 0;
    var lawyerInfo = [
        '<strong>Immigration lawyer</strong> <br> Igor Yushchenko',
        '<strong>Professional mortgage broker</strong> <br> Igor Geshelin',
        '<strong>Real Estate expert</strong> <br> Valentina Kenny',
        '<strong>Immigration lawyer</strong> <br> Godfred T.Chongatera'
    ];
    var lawyerText = [
        'LL.B, LL.M, LL.M, C.Q. Barrister & Solicitor Bacchus & Associates Law Firm <br> 6189 Lady Hammond Road Halifax, NS B3K 2 R9',
        'The Bentley Group at Premiere Mortgage Centre 1450 Dresden Row, Halifax, NS B3J 3T5 <br> 902-441-6493 <br> <a href = "mailto: igor.geshelin@premieremortgage.ca">igor.geshelin@premieremortgage.ca</a> <a href="http://www.thinkbentley.com">www.thinkbentley.com</a>',
        'Real Estate expert Valentina Kenny Real Estate Sales and Leasing Royal Lepage Atlantic 7071 Bayers Rd.Halifax, NS <br> 902 - 497 - 4467 <br> <a href = "mailto: val@showhouse.ca">val@showhouse.cal</a> <br> <a href="http://www.showhouse.ca">www.showhouse.ca</a>',
        'B.A., LL.B, M.A, LL.M, C.Q. Barrister & Solicitor <br> MK Law <br> 608 - 1888 Brunswick Street Halifax, NS B3J 3 J8 <a href="http://www.gtclawfirm.com/">www.gtclawfirm.com</a>'
    ];
    $('#back').click(function () {
        $('#lawyer' + photoNum).fadeOut(500).removeClass('lawyer-active');
        if (photoNum <= 1 && i <= 0) {
            photoNum = 4;
            i = 3;
        } else {
            photoNum--;
            i--;
        }
        $('#lawyer' + photoNum).fadeIn(500).addClass('lawyer-active');
        $('.lawyer_text').html(lawyerInfo[i]);
        $('.lawyer_info').html(lawyerText[i]);
    });
    $('#next').click(function () {
        $('#lawyer' + photoNum).fadeOut(500).removeClass('lawyer-active');
        if (photoNum >= 4 && i >= 3) {
            photoNum = 1;
            i = 0;
        } else {
            photoNum++;
            i++;
        }
        $('#lawyer' + photoNum).fadeIn(500).addClass('lawyer-active');
        $('.lawyer_text').html(lawyerInfo[i]);
        $('.lawyer_info').html(lawyerText[i]);
    });
    /**
     * Login
     */
    $('#login_form').submit(function (e) {
        e.preventDefault();
        let form = $(this);
        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function (data) {
                $('#login').modal('hide');
                if (data.route) {
                    window.location.href = data.route;
                }
            },
            error: function (data) {
                if (data.responseJSON && data.responseJSON.errors) {
                    if (data.responseJSON.errors.email) {
                        $("#emailm").css('color', '#fc0059').html(data.responseJSON.errors.email);
                    } else {
                        $("#emailm").html('');
                    }
                }
            }
        })
    });

    /**
     * Register
     */
    $('#register_form').submit(function (e) {
        e.preventDefault();
        let form = $(this);

        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function (data) {
                $('#register').modal('hide');
                window.location.href = data.route;
            },
            error: function (data) {
                if (data.responseJSON && data.responseJSON.errors) {
                    if (data.responseJSON.errors.name) {
                        $("#namemr").css('color', '#fc0059').html(data.responseJSON.errors.name);
                    } else {
                        $("#namemr").html('');
                    }
                    if (data.responseJSON.errors.email) {
                        $("#emailmr").css('color', '#fc0059').html(data.responseJSON.errors.email);
                    } else {
                        $("#emailmr").html('');
                    }
                    if (data.responseJSON.errors.password) {
                        $("#passwordmr").css('color', '#fc0059').html(data.responseJSON.errors.password);
                    } else {
                        $("#passwordmr").html('');
                    }
                }
            }
        });
    });

    /**
     * Disabled button
     */
    $('#checkbox').change(function () {
        if ($(this).is(':checked')) {
            $('#register_submit_button').removeAttr('disabled');
        } else {
            $('#register_submit_button').attr('disabled', 'disabled');
        }
    });

    $('.reviews_slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        variableWidth: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            }
        },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });
    var videoContainer = $('#main_video')[0];
    var videoPlayer = $('#main_video');
    var stopVideo = function () {
        videoContainer.pause();
        videoContainer.currentTime = 0;
    };
    $('.close_video').on('click', stopVideo);
    $('#video').on('click', function (e) {
        if (e.target === videoContainer) {
            videoPlayer.on('click', function (e) {
                e.preventDefault();
                if (videoContainer.paused) {
                    videoContainer.play();
                    return false
                }
                if (videoContainer.played) {
                    videoContainer.pause();
                    return false
                }
            })
        } else stopVideo()
    });

});


/**
 * intl-tel-input
 * @type {*|jQuery|HTMLElement}
 */
/***==========START==========***/
var telInput = $("#phone"),
    errorMsg = $("#error-msg"),
    validMsg = $("#valid-msg");

/**
 * intl-tel-input initialise
 */
telInput.intlTelInput({

    allowExtensions: true,
    formatOnDisplay: true,
    autoFormat: true,
    autoHideDialCode: true,
    autoPlaceholder: true,
    defaultCountry: "",
    ipinfoToken: "yolo",

    nationalMode: false,
    numberType: "MOBILE",
    //onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
    preferredCountries: ['sa', 'ae', 'qa', 'om', 'bh', 'kw', 'ma'],
    preventInvalidNumbers: true,
    // separateDialCode: true,
    initialCountry: "ua",
    geoIpLookup: function (callback) {
        $.get("http://ipinfo.io", function () {
        }, "jsonp").always(function (resp) {
            var countryCode = (resp && resp.country) ? resp.country : "";
            callback(countryCode);
        });
    },
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.9/js/utils.js"
});

/**
 * intl-tel-input clear
 */
var reset = function () {
    telInput.removeClass("error");
    errorMsg.addClass("hide");
    validMsg.addClass("hide");
};

/**
 * on blur: validate
 */
telInput.blur(function () {
    reset();
    if ($.trim(telInput.val())) {
        if (telInput.intlTelInput("isValidNumber")) {
            validMsg.removeClass("hide");
        } else {
            telInput.addClass("error");
            errorMsg.removeClass("hide");
        }
    }
});

/**
 * on keyup / change flag: reset
 */
telInput.on("keyup change", reset);
/***==========END==========***/
// $(document).ready(function () {
//     let self = $('#ModalPayment');
//     self.ready(function () {
//         $('#payment_product_type_value').val(self.data('default_type'));
//         let src = self.data('product_src');
//         if (src) {
//             $('#payment_product_img').removeClass('d-none').attr('src', src);
//         } else {
//             $('#payment_product_img').addClass('d-none').attr('src', '');
//         }
//     });
// });
$(document).ready(function () {
    $('#navbar-toggler').click(function () {
        $('#navbarNav').removeClass('hide_burger');
    });
});


$(document).ready(function () {
    /**
     * Send forgot password
     */
    $('#forgot_password_form').submit(function (event) {
        event.preventDefault();
        let form = $(this);

        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function (data) {
                if (data.message) {
                    $('#forgot_password_message').removeClass().addClass('alert alert-success').html(data.message);
                }
            },
            error: function (data) {
                if (data.responseJSON.message) {
                    $('#forgot_password_message').removeClass().addClass('alert alert-danger').html(data.responseJSON.message);
                }
            }
        });
    });

    /**
     * New password
     */
    $('#new_password_form').submit(function (event) {
        event.preventDefault();
        let form = $(this);

        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function (data) {
                if (data.message) {
                    $('#new_password_message').removeClass().addClass('alert alert-success').html(data.message);
                    window.location.href = data.redirect
                }
            },
            error: function (data) {
                let response = data.responseJSON;
                if (response) {
                    if (response.message) {
                        $('#new_password_message').removeClass().addClass('alert alert-danger').html(response.message);
                    }
                    let errors = response.errors;
                    if (!jQuery.isEmptyObject(errors)) {
                        let errorsHtml = '<div class="alert alert-danger" role="alert"><ul>';
                        $.each(errors, function (key, value) {
                            errorsHtml += '<li>' + value[0] + '</li>';
                        });
                        errorsHtml += '</ul></div>';
                        $('#new_password_messages').html(errorsHtml);
                    }
                }
            },
        });
    });
    /**
     *  CheckDoc
     */
    $('#check_your_doc_form').submit(function (event) {
        event.preventDefault();
        let form = $(this);
        let data = new FormData(form[0]);
        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            dataType: 'json',
            processData: false,
            contentType: false,
            data: data,
            success: function (data) {
                $('#list_files').html("");
                $('#check_your_doc_form_errors').html("");
                $('#check_your_doc_form_success').show();
                form.trigger('reset');
            },
            error: function (data) {
                $('#check_your_doc_form_success').hide();
                let response = data.responseJSON;
                if (response) {
                    let errors = response.errors;
                    if (!$.isEmptyObject(errors)) {
                        let errorsHtml = '<div class="alert alert-danger" role="alert"><ul>';
                        $.each(errors, function (key, value) {
                            errorsHtml += '<li>' + value[0] + '</li>';
                        });
                        errorsHtml += '</ul></div>';

                        $('#check_your_doc_form_errors').html(errorsHtml);
                    }
                }

            }
        });
    });

    /**
     * Set product in productInfo(Modal)
     */
    $('.products_list').click(function () {

        let route = $(this).data('route');
        let src = $(this).data('product_src');
        let title = $(this).data('product_title');
        let description = $(this).data('product_description');
        let PrePrice = $(this).data('product_pre_price');
        let price = $(this).data('product_price');
        if (src) {
            $('#product_info_src').removeClass('d-none').attr('src', src)
        } else {
            $('#product_info_src').addClass('d-none').attr('src', '')
        }
        $('#product_info_title').html(title).attr('title', title);
        $('#product_info_description').html(description);
        $('#product_info_pre_price').html('$' + PrePrice + '!!!');
        $('#product_info_price').html('$' + price);
        $('#product_info_button').attr('data-route', route);

    });
    /**
     * Set video data in modal
     */
    $('.set_video_data').click(function () {
        let src = $(this).data('src');
        let description = $(this).data('description');
        $('#video_source').attr('src', src);
        $('#video_description').html(description);
        let vid = document.getElementById('video_video');
        vid.autoplay = true;
        vid.load();
    });
    $('.redirect_to').click(function () {
        window.location.href = $(this).data('route');
    });
    /**
     * Pause video after close modal
     */
    $('#VideoModal').on('hidden.bs.modal', function () {
        $('#video_source').attr('src', '');
        $('#video_description').html('');
        let vid = document.getElementById('video_video');
        vid.pause();
    });

    /**
     * Go to payment page
     */
    $('#product_info_button').click(function () {
        window.location.href = $(this).data('route');
    });
    /**
     * Go to payment page
     */
    $('.slider_button').click(function () {
        window.location.href = $(this).data('route');
    });

    /**
     * CheckPromoCode
     */
    // $('#product_promo_code_form').submit(function (e) {
    //     e.preventDefault();
    //     let form = $(this);
    //
    //     $.ajax({
    //         method: form.attr('method'),
    //         url: form.attr('action'),
    //         data: form.serialize(),
    //         success: function (data) {
    //             $('#promo_code_error').hide().html("");
    //             $('#promo_code_payments').show();
    //             if (data.message) {
    //                 $('#promo_code_success').show().html("success");
    //             } else {
    //                 $('#promo_code_success').hide().html("");
    //             }
    //             $('#promo_code_product_cost').show().html(data.price + '$');
    //         },
    //         error: function (data) {
    //             $('#promo_code_product_cost').hide().html("");
    //             $('#promo_code_success').hide().html("");
    //             $('#promo_code_payments').hide("");
    //             let response = data.responseJSON;
    //             if (response) {
    //                 if (response.message) {
    //                     $('#promo_code_error').show().html(response.message);
    //                 }
    //             }
    //         }
    //     });
    // });
    $('#product_promo_code_form_2').submit(function (e) {
        e.preventDefault();
        let form = $(this);
        let totalDiv = $('#promo_code_product_cost_2');
        let discountDiv = $('#promo_code_product_discount');
        let errorDiv = $('#promo_code_error_2');
        let successDiv = $('#promo_code_success_2');

        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function (data) {
                errorDiv.hide().html('');
                successDiv.show().html("success");
                discountDiv.html('%' + data.discount);
                totalDiv.html('$' + data.amount);

            },
            error: function (data) {
                errorDiv.hide().html('');
                successDiv.hide().html('');
                discountDiv.html('%0');
                totalDiv.html($('#price').val());
                let response = data.responseJSON;
                if (response) {
                    if (response.message) {
                        errorDiv.show().html(response.message);
                    }
                }
            }
        });
    });
    // $('#product_promo_code_form_video').submit(function (e) {
    //     e.preventDefault();
    //     let form = $(this);
    //
    //     $.ajax({
    //         method: form.attr('method'),
    //         url: form.attr('action'),
    //         data: form.serialize(),
    //         success: function (data) {
    //             $('#promo_code_error_video').hide().html("");
    //             $('#promo_code_payments_video').show();
    //             if (data.message) {
    //                 $('#promo_code_success_video').show().html("success");
    //             } else {
    //                 $('#promo_code_success_video').hide().html("");
    //             }
    //             $('#promo_code_product_cost_video').show().html(data.price + '$');
    //         },
    //         error: function (data) {
    //             $('#promo_code_product_cost_video').hide().html("");
    //             $('#promo_code_success_video').hide().html("");
    //             $('#promo_code_payments_video').hide("");
    //             let response = data.responseJSON;
    //             if (response) {
    //                 if (response.message) {
    //                     $('#promo_code_error_video').show().html(response.message);
    //                 }
    //             }
    //         }
    //     });
    // });
    // /**
    //  * Show CheckPromoCodeForm
    //  */
    // $('#ModalPayment').on('hidden.bs.modal', function () {
    //     $('#product_promo_code_form').trigger('reset');
    //     $('#promo_code_payments').hide();
    //     $('#promo_code_error').hide().html("");
    //     $('#promo_code_success').hide().html("");
    // });
    // /**
    //  * Show CheckPromoCodeForm
    //  */
    // $('#ModalPaymentVideo').on('hidden.bs.modal', function () {
    //     $('#product_promo_code_form_video').trigger('reset');
    //     $('#promo_code_payments_video').hide();
    //     $('#promo_code_error_video').hide().html("");
    //     $('#promo_code_success_video').hide().html("");
    // });

    // /**
    //  * Show adyen product
    //  */
    // $('#payment_adyen_button').click(function () {
    //     $('#adyenModalProduct').modal('show');
    // });
    /**
     * Redirect to paypal
     */
    $('#payment_paypal_button').click(function () {
        window.location.href = $(this).data('redirect');
    });

    // /**
    //  * Show adyen modal product
    //  */
    // $('#modal_payment_adyen_button').click(function () {
    //     $('#ModalPayment').modal('hide');
    //     $('#adyenModalProduct').modal('show');
    // });
    // /**
    //  * Redirect to paypal
    //  */
    // $('#modal_payment_paypal_button').click(function () {
    //     window.location.href = $(this).data('redirect');
    // });
    // /**
    //  * Show adyen modal product
    //  */
    // $('#modal_payment_adyen_button_video').click(function () {
    //     $('#ModalPaymentVideo').modal('hide');
    //     $('#adyenModalProduct').modal('show');
    // });
    // /**
    //  * Redirect to paypal
    //  */
    // $('#modal_payment_paypal_button_video').click(function () {
    //     window.location.href = $(this).data('redirect');
    // });
});

/**
 * Send Verified Link
 */
$(document).ready(function () {
    $('.send_verified_link').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            method: "POST",
            url: $(this).data('route'),
            data: {
                _token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
            },
            error: function (data) {
            }
        });
    });
});

//modal forgot password. hide modal sign in
$('.sign-in__link').click(function () {
    $('#login').modal('hide')

});
///slider
///
// $('.children_slider').click(function () {
//     $('.parent_div').find('.children_active').removeClass('children_active');
//     $(this).toggleClass('children_active');
// });


//////////////////////////////my_Slider
// $(document).ready(function () {
//     $('.children_slider')
//         .click(function (e) {
//             let data_children=$(this).data("children");
//             $('.parent_div').find('.children_active').data("children",data_children);
//             // if($(this).hasClass('children_left')){
//             //     $('.children_active').css({
//             //         "left": "28%",
//             //     });
//             // }
//
//             $(this).removeClass(data_children);
//             console.log('dd_chi',data_children);
//             $(this).data("children", 'children_active');
//             $('.parent_div').find('.children_active').addClass(data_children);
//             $('.parent_div').find('.children_active').removeClass('children_active');
//             $(this).toggleClass('children_active');
//         })
//         .on('click', '.btn_aply', function (e) {
//             e.stopPropagation();
//             $('#register').modal('show')
//         });
// });

///////////////////////////////////
/**
 * Query  events
 */
$(document).ready(function () {
    let getModalId = getUrlParameter('modalShow');
    /**
     * Show or close modal
     */
    if (getModalId) {
        let id = "#" + getModalId;
        $(id).modal('show');
        $(id).on('hidden.bs.modal', function () {
            if (id === '#newPasswordCenter' || id === '#verified') {
                cleanUrlParameter();
            }

        })
    }
    let token = getUrlParameter('reset_token');
    /**
     * Check if have reset_token
     */
    if (token) {
        $('#new_token').val(token);
    }
});
/**
 * Get query
 * @param sParam
 * @returns {*}
 */
let getUrlParameter = function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};
/**
 * Clear query
 */
let cleanUrlParameter = function cleanUrlParameter() {
    let clean_uri = location.protocol + "//" + location.host + location.pathname;
    window.history.replaceState({}, document.title, clean_uri);
};
///slider
///
// $('.children_slider').click(function () {
//     $('.parent_div').find('.children_active').removeClass('children_active');
//     $(this).toggleClass('children_active');
// });
$(document).ready(function () {
    $('.children_slider')
        .click(function (e) {
            let data_children = $(this).data("children");
            $('.parent_div').find('.children_active').data("children", data_children);
            $(this).removeClass(data_children);
            $(this).data("children", 'children_active');
            $('.parent_div').find('.children_active').addClass(data_children);
            $('.parent_div').find('.children_active').removeClass('children_active');
            $(this).toggleClass('children_active');
        })
        .on('click', '.btn_aply', function (e) {
            e.stopPropagation();
            let modal = $(this).data('target');
            if (modal) {
                $(modal).modal('show')
            }

        });
});
//
// /**
//  *  Payment Adyen
//  */
// $(document).ready(function () {
//     $('#adyen_payment').click(function () {
//         $($(this).data('close_modal')).modal('hide');
//         $($(this).data('show_modal')).modal('show');
//     });
//
//     $('#adyen_country').on('change', function (event) {
//         event.preventDefault();
//         let form = $('#adyen_payment_methods');
//         let dropDiv = $("#dropin-container");
//         $.ajax({
//             method: form.attr('method'),
//             url: form.attr('action'),
//             data: form.serialize(),
//             success: function (data) {
//                 dropDiv.removeClass('alert alert-warning');
//                 const configuration = {
//                     paymentMethodsResponse: data.result, // The `/paymentMethods` response from the server.
//                     originKey: data.origin_key,
//                     locale: data.locale,
//                     environment: "test",
//                     onSubmit: (state, dropin) => {
//                         makePayment(state.data);// Your function calling your server to make the `/payments`
//                     },
//                     onAdditionalDetails: (state, dropin) => {
//                         // Your function calling your server to make a `/payments/details` request
//                     },
//                     paymentMethodsConfiguration: {
//                         card: { // Example optional configuration for Cards
//                             hasHolderName: true,
//                             holderNameRequired: true,
//                             enableStoreDetails: true,
//                             hideCVC: false, // Change this to true to hide the CVC field for stored cards
//                             name: 'Credit or debit card'
//                         }
//                     }
//                 };
//                 const checkout = new AdyenCheckout(configuration);
//                 const dropin = checkout.create('dropin').mount('#dropin-container');
//             },
//             error: function (data) {
//                 dropDiv.removeClass('alert alert-warning');
//                 if (data.responseJSON) {
//                     if (data.responseJSON.message) {
//                         dropDiv.addClass('alert alert-warning').html(data.responseJSON.message);
//                     }
//                 }
//
//             },
//         });
//     });
//     /**
//      * @param data
//      */
//     let makePayment = function makePayment(data) {
//         $.ajax({
//             method: "POST",
//             url: $("#dropin-container").data('route_payment'),
//             data: {
//                 _token: $('meta[name="csrf-token"]').attr('content'),
//                 data: data
//             },
//             success: function (data) {
//                 if (data.redirect) {
//                     window.location.href = data.redirect
//                 }
//             },
//             error: function (data) {
//                 $("#adyen_payment_methods").trigger('reset');
//             }
//         });
//     }
// });

/////////////////
$('#cascade-slider').cascadeSlider({
    itemClass: 'cascade-slider_item',
    arrowClass: 'cascade-slider_arrow'
});
///

// $(".cascade-slider_item.now").height();
// console.log('height',$(".cascade-slider_item.now").height());
// let slider_height=$(".cascade-slider_item.now").height();
//    $('.cascade-slider_container').addClass('REDHEIGHT')
// $(".cascade-slider_container").css("height", 'calc(slider_height- 20px)');

//////////////////////////////////COLAPSE FOR VIDEO
$('.collapse').collapse();
/**
 * Show modal for NewUser
 */
$(document).ready(function () {
    let item = $('#user_id');
    if (item.length) {
        let user_id = 'u' + item.val();
        if (localStorage.getItem(user_id) === null) {
            $('#modalNewUser').modal('show');
            localStorage.setItem(user_id, '1');
        }
    }
});

// $('.btn_play').click(function() {
//     let vv=($(parent).find('.block_video'));
//     console.log(($(parent).find('.block_video')).i);
//
//
//         $(vv).trigger('play');
// });

// $(document).ready(function () {
//     $('.btn_play').click(function () {
//         let parent = $(this).parent(".wrapper_video");
//         $(parent).find('img').addClass('img_hide');
//         $(parent).find('.block_video').removeClass('video_hidden');
//     });
// });


///////////
// console.log($("#video"))
// $("#video").videoLayers({
//     layers:[
//         {
//             element:"#external-layer",
//             appendToLayers:false, //by default the layers are appended to the layer container
//             start:1,
//             stop:4,
//             show:{effect:"fade",duration:1000},
//             hide:{effect:"fade",duration:1000}
//         },
//         {
//             element:"#layer-img",
//             cssClass:"addThisClass and This One c-video-layers__layer--strip",
//             styles:{
//                 width:"50%"
//             },
//             position:{
//                 my:"center",
//                 to:"center"
//             },
//             start:5,
//             stop:10,
//             show:{effect:"fade",duration:1000},
//             hide:{effect:"blind",duration:500}
//         }
//     ]
// })
//////////////

// $(document).ready(function() {
//     $.ajax({
//         type:"GET",
//         url:"/user-cabinet/video/25",
//         // data: $("#formSubscribe").serialize(),
//         data:'',
//         success: function(data){
//             console.log('Ajax responded');
//             console.log('data', document.getElementById('sourceVid25'));
//             document.getElementById('sourceVid25').src=data;
//         },
//         error: function (data) {
//             console.log(111);
//         }
//     });
// });
//////////////modalpaymentVideo
$(document).ready(function () {
    $('.btn_product').click(function () {
        $('.btn_product').removeClass('active_btn');
        $(this).addClass('active_btn');
        $('#payment_product_type_value_video').val($(this).data('value'))

    });
});

$(document).ready(function () {
    $('.btn_continue').click(function () {
        $(this).css('display', 'none');

    });
});

$('#ModalPayment').on('shown.bs.modal', function () {
    $('.btn_continue').css('display', 'block');
});
$('#ModalPaymentVideo').on('shown.bs.modal', function () {
    $('.btn_continue').css('display', 'block');
});
///////////////
$('#close_register').click(function () {
    $('#register').modal('hide')

});
////////////////////$(div)[0].scrollHeight
// $('.blockVideo_wrapper').ready(function () {
//     var height_document = $('.blockVideo_wrapper').height();
//     var height_client = $('.blockVideo_wrapper').clientHeight;
//     var maxHeight = parseInt($('.blockVideo_wrapper').css('max-height'));
//     if ($('.blockVideo_wrapper')[0]) {
//
//         if (($('.blockVideo_wrapper')[0].scrollHeight) > maxHeight) {
//             $('.scroll').addClass('gradient');
//         }
//     }
//
//
// });


// mob_education
$(document).ready(function () {
    $('#btn_education').click(function () {
        $('.educationMob').toggleClass('education_hide');

    });
});


/**
 * Update User Email Only
 */
$(document).ready(function () {
    $('#update_user_email').submit(function (e) {
        e.preventDefault();
        let form = $(this);
        $.ajax({
            method: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            success: function (data) {
                $('#update_user_email_error').addClass('d-none').html();
                if (data.route) {
                    $('#verified').modal('hide');
                    window.location.href = data.route;
                }
            },
            error: function (data) {
                let divId = $('#update_user_email_error');
                divId.addClass('d-none').html();
                let response = data.responseJSON;
                if (response.errors.email) {
                    divId.removeClass('d-none').html('<strong>' + response.errors.email + '</strong>');
                }
            }
        })
    });
});

///
$(document).ready(function () {
    let slides = $('.cascade-slider_arrow');
    for (let i = 0; i < slides.length; i++) {
        $(slides[i]).removeClass('now');
        $(slides[i]).removeClass('next');
        $(slides[i]).removeClass('prev');
    }
    $(slides[0]).addClass('prev');
    $(slides[1]).addClass('now');
    $(slides[2]).addClass('next');
});


//start main video
$('#StartVideo_modal').click(function () {
    console.log('video');
    $('#main_video').trigger('play');
});
///

$(document).ready(function () {
    if ($("#consultation_add").length !== 0) {
        $('#timeConsultation').text(localStorage.getItem('timeConsultation'))
        $('#dateConsultation').text(localStorage.getItem('dateConsultation'))
        $('#modalSuccessCreateConsultation').modal('show')
    }
});
