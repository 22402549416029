var pullfiles=function(){
    // love the query selector
    var fileInput = document.querySelector("#myfiles");
    var files = fileInput.files;
    // cache files.length
    var fl = files.length;
    var i = 0;
    while ( i < fl) {
        // localize file var in the loop
        var file = files[i];
        let liFirst = document.createElement('li');
        let btn = document.createElement('button');

        btn.className='RemoveFile';
        btn.dataset.dataNumb=i;
        btn.innerHTML = "    <svg aria-hidden=\"true\" focusable=\"false\" data-prefix=\"fas\" data-icon=\"times\" class=\"svg-inline--fa fa-times fa-w-11\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 352 512\"><path  d=\"M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z\"></path></svg>";
        liFirst.innerHTML ='<span class="text_file">'+file.name+'</span>';
        liFirst.className='file_teg';
        liFirst.append(btn);
        document.getElementById('list_files').prepend(liFirst); // вставить liFirst в начало <ol>
         // document.getElementById('ol').value=file.name;
        i++;
        let    removeBtn = document.querySelector('.RemoveFile');
       let dd = document.querySelector('.file_teg');

        removeBtn.onclick = function(e) {
            removeBtn.remove();
            dd.remove();
        };


    }

};

if( document.querySelector("#myfiles")){
    document.querySelector("#myfiles").onchange=pullfiles;



}

// set the input element onchange to call pullfiles

