import {Calendar} from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interaction from '@fullcalendar/interaction';
import allLocales  from '@fullcalendar/core/locales-all';
import moment from 'moment';
import { toMoment } from '@fullcalendar/moment';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import axios from 'axios';
require('./api')
require('./client')
let initialLocaleCode = 'uk';
let dateStart;
let dateEnd;
let localeSelectorEl = document.getElementById('locale-selector');
function launtchUserModal(selected) {
    $('#eventDate').val(moment(selected.start).utc().format('YYYY-MM-DD'));
    $('#eventTime').val(moment(selected.start).utc().format('HH:mm'));
    $('#eventDateForModal').val(moment(selected.start).utc().format('YYYY-MM-DD'));
    $('#eventTimeForModal').val(moment(selected.start).tz("America/Halifax").format('HH:mm'));
    $('#eventTimeTo').val(moment(selected.end).utc().format('HH:mm'));
    dateStart= $('#dateStart').val(moment(selected.start).tz("America/Halifax").format('DD.MM.YYYY HH:mm'));
    dateEnd=$('#dateEnd').val(moment(selected.end).tz("America/Halifax").format('DD.MM.YYYY HH:mm'));
    $('#hours').text(moment(selected.end).diff(moment(selected.start), 'minutes') / 60.0);

}
let calendar;
let LoadedEvents = false;
let googleCalendarApiKey='AIzaSyDlC__1Z2vg03RCInM-Npy--Hv-1ESf2PY';
let calendarId='generalimmigrantclub@gmail.com'

// document.addEventListener('DOMContentLoaded', function() {
function RenderCalendar(){
    if(document.getElementById('calendar')){
        let calendarEl = document.getElementById('calendar');
        // $(calendarEl).draggable();
        calendar = new Calendar(calendarEl, {
            slotDuration: '01:00',
            slotMinTime  : '7:00:00' ,
            slotMaxTime  : '23:00:00' ,
            locales: allLocales,
            locale: initialLocaleCode,
            plugins: [interaction, timeGridPlugin, googleCalendarPlugin,momentTimezonePlugin],
            longPressDelay: 0.5,
            selectLongPressDelay:0,
            googleCalendarApiKey: googleCalendarApiKey,
            events: {
                googleCalendarId: calendarId,
            },



            dateClick: function(arg) {
                // millisecond value is correctly in America/New_York
                console.log(arg.date.valueOf())
                // use the plugin for manipulation and formatting
                let m = toMoment(arg.date, calendar)
                console.log(m.format()) // something like '2018-09-01T12:30:00-05:00'
            },
            defaultView: 'timeGridWeek',
            nowIndicator: true,
            selectable: true,
            timeZone:'America/Halifax',
            select: function (selected) {
                if(moment(selected.end).diff(moment(selected.start),'minutes') > 60){
                    swal($('#swal_messages').data('maximum_booking'))
                }else if(moment(selected.start).tz("America/Halifax")<moment().tz("America/Halifax")){
                    swal($('#swal_messages').data('date_already_passed'));
                }
                else
                    // console.log('tz',moment(selected.start).tz("America/Halifax").weekday());
                if((moment(selected.start).tz("America/Halifax").weekday())==6||(moment(selected.start).tz("America/Halifax").weekday())==0){
                    swal($('#swal_messages').data('weekend'));
                }
                else {
                    launtchUserModal(selected);
                    dateStart = moment(selected.start).utc().format();
                    dateEnd = moment(selected.end).utc().format();
                    loadEvents(dateStart,dateEnd);
                    $('#consultation_formOrder').modal('show');
                    console.log('locale',dateStart)

                }
            },
        });
        calendar.getAvailableLocaleCodes().forEach(function(localeCode) {
            let optionEl = document.createElement('option');
            optionEl.value = localeCode;
            optionEl.selected = localeCode == initialLocaleCode;
            optionEl.innerText = localeCode;
            localeSelectorEl.appendChild(optionEl);
        });
    }
}


// });
if(document.getElementById('calendar')){


    localeSelectorEl.addEventListener('change', function() {
        if (this.value) {
            calendar.setOption('locale', this.value);
        }
    });
}

function loadEvents(dateStart,dateEnd) {
    calendar.render();
}

$('#calendarModal').on('shown.bs.modal', function () {
    RenderCalendar();
    GeneratePublicCalendar();

})
////////////////////////


function GeneratePublicCalendar(){
    // You need a normal fullcalendar with googleApi when user isn't logged
    console.log('render');
    calendar.render();
}
// function load the calendar api and make the api call

//////////////////////////////
